import React, { useState } from 'react';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

const PhotoPreferenceForm = ({ onPreferencesSubmit, onRetrain }) => {
  const [preferences, setPreferences] = useState({
    mood: '',
    style: '',
    useCase: '',
    lighting: '',
    background: '',
    attire: ''
  });
  const [sudoMode, setSudoMode] = useState(false);
  const [customPrompt, setCustomPrompt] = useState('');

  const handleChange = (e) => {
    setPreferences({
      ...preferences,
      [e.target.name]: e.target.value
    });
  };

  const handleSudoModeToggle = () => {
    setSudoMode(!sudoMode);
    setCustomPrompt(''); // Clear custom prompt when toggling
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const prompt = sudoMode
      ? customPrompt
      : `Imagine me in a ${preferences.style} style portrait with a ${preferences.background} background. ` +
        `I should look ${preferences.mood} in ${preferences.attire} attire, lit by ${preferences.lighting}, for my ${preferences.useCase}.`;

    if (sudoMode && !customPrompt.trim()) {
      alert('Please provide a custom description for Sudo Mode!');
      return;
    }

    if (!sudoMode && Object.values(preferences).some(val => val === '')) {
      alert('Complete all options to craft your perfect portrait!');
      return;
    }

    const token = localStorage.getItem('access_token');

    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/images/generate_image`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ prompt, sudo_mode: sudoMode }),
        mode: 'cors'
      });

      const result = await response.json();
      if (response.ok) {
        onPreferencesSubmit(result);
      } else {
        throw new Error(result.error || 'Failed to generate image');
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  const handleRetrain = () => {
    onRetrain();
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Design Your Dream Portrait! 🌟</h2>
      <Analytics />
      <SpeedInsights />
      {!sudoMode && (
        <div>
          <p>
            Imagine me in a <strong><select name="style" value={preferences.style} onChange={handleChange}>
              <option value="">[select style]</option>
              <option value="classic">Classic Elegance</option>
              <option value="modern">Modern Chic</option>
              <option value="vintage">Timeless Vintage</option>
              <option value="artistic">Artistic Expression</option>
              <option value="futuristic">Futuristic Vibes</option>
              <option value="anime">Anime Aesthetic</option>
            </select></strong> style with a <strong><select name="background" value={preferences.background} onChange={handleChange}>
              <option value="">[select background]</option>
              <option value="studio">Professional Studio</option>
              <option value="urban">Urban Jungle</option>
              <option value="nature">Serene Nature</option>
              <option value="abstract">Abstract Colors</option>
              <option value="galaxy">Galaxy Universe</option>
              <option value="minimalist">Minimalist Charm</option>
            </select></strong> background. I should look <strong><select name="mood" value={preferences.mood} onChange={handleChange}>
              <option value="">[select mood]</option>
              <option value="serious">Serious</option>
              <option value="smiling">Cheerfully Smiling</option>
              <option value="thoughtful">Deep in Thought</option>
              <option value="joyful">Full of Joy</option>
              <option value="mysterious">Mysterious</option>
            </select></strong> in <strong><select name="attire" value={preferences.attire} onChange={handleChange}>
              <option value="">[select attire]</option>
              <option value="casual">Casual Comfort</option>
              <option value="formal">Sophisticated Formal</option>
              <option value="sporty">Sporty Style</option>
              <option value="elegant">Elegant Glamour</option>
              <option value="fantasy">Fantasy Costume</option>
            </select></strong> attire, lit by <strong><select name="lighting" value={preferences.lighting} onChange={handleChange}>
              <option value="">[select lighting]</option>
              <option value="natural">Soft Natural Light</option>
              <option value="studio">Studio Spotlight</option>
              <option value="dramatic">Dramatic Shadows</option>
              <option value="neon">Vibrant Neon Glow</option>
            </select></strong> for my <strong><select name="useCase" value={preferences.useCase} onChange={handleChange}>
              <option value="">[select use case]</option>
              <option value="LinkedIn profile">Professional LinkedIn Profile</option>
              <option value="Tinder bio">Eye-catching Tinder Bio</option>
              <option value="Portfolio">Creative Portfolio</option>
              <option value="Social Media">Fun Social Media Post</option>
            </select></strong>.
          </p>
        </div>
      )}
      {sudoMode && (
        <div>
          <label>
            Enter your custom portrait prompt:
            <textarea
              name="customPrompt"
              value={customPrompt}
              onChange={(e) => setCustomPrompt(e.target.value)}
              placeholder="Describe your ideal portrait (e.g., 'Me as a knight in a medieval castle')"
            />
          </label>
        </div>
      )}
      <div>
        <label>
          <input type="checkbox" checked={sudoMode} onChange={handleSudoModeToggle} />
          Enable Sudo Mode
        </label>
      </div>
      <button type="submit">Create My Portrait</button>
      <button type="button" onClick={handleRetrain}>Force Retrain Model</button>
    </form>
  );
};

export default PhotoPreferenceForm;
