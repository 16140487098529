import React, { useState, Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stars, PerspectiveCamera } from '@react-three/drei';
import StudioEquipment from './StudioEquipment';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

const AuthScreen = ({ onAuthComplete, imageUrl }) => {
    const [isLogin, setIsLogin] = useState(true);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        name: '' 
    });
    const [error, setError] = useState('');
    const [authenticated, setAuthenticated] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validateForm = () => {
        setError('');
        if (!formData.email || !formData.password) {
            setError('Please fill in all fields');
            return false;
        }
        if (!isLogin && (formData.password !== formData.confirmPassword || !formData.name)) {
            setError('Passwords do not match or name is missing');
            return false;
        }
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            setError('Please enter a valid email address');
            return false;
        }
        if (formData.password.length < 8) {
            setError('Password must be at least 8 characters long');
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const url = `${process.env.REACT_APP_BACKEND_URL}/auth${isLogin ? '/login' : '/register'}`;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    email: formData.email,
                    password: formData.password,
                    ...(isLogin ? {} : { name: formData.name })  // Include name if registering
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.access_token) {
                    localStorage.setItem('access_token', data.access_token);
                    setAuthenticated(true);
                    onAuthComplete(true, data.model_trained);
                } else {
                    setError(data.message);
                }
            })
            .catch(error => {
                setError('Failed to authenticate. Please try again. ' + error.message);
            });
        }
    };

    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <Canvas>
                <PerspectiveCamera makeDefault position={[0, 1, 4]} />
                <ambientLight intensity={1} />
                <pointLight position={[10, 10, 10]} intensity={2} />
                <Suspense fallback={null}>
                    <Stars />
                    <StudioEquipment />
                </Suspense>
                <OrbitControls
                    enableZoom={true}
                    maxDistance={4}
                    minDistance={0}
                    enablePan={false}
                />
            </Canvas>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10 }}>
                <h2>{isLogin ? 'Login' : 'Register'}</h2>
                <Analytics />
                <SpeedInsights />
                <form onSubmit={handleSubmit}>
                    {!isLogin && (
                        <>
                            <input type="name" name="name" placeholder="Name" value={formData.name} onChange={handleChange} />
                            <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                            <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} />
                            <input type="password" name="confirmPassword" placeholder="Confirm Password" value={formData.confirmPassword} onChange={handleChange} />
                        </>
                    )}
                    {isLogin && (
                        <>
                            <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                            <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} />
                        </>
                    )}
                    <button type="submit">{isLogin ? 'Login' : 'Register'}</button>
                </form>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <button onClick={() => setIsLogin(!isLogin)}>
                    {isLogin ? 'Need to register?' : 'Already have an account? Log in'}
                </button>
                {authenticated && imageUrl && (
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: `url(${imageUrl}) no-repeat center center`, backgroundSize: 'cover', zIndex: 1000 }}>
                        <button onClick={() => setAuthenticated(false)} style={{ position: 'absolute', top: 20, right: 20 }}>Close</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AuthScreen;
