import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const WaitingPage = ({ photos, onTrainingComplete }) => {
  const [status, setStatus] = useState('Uploading photos...');
  const [attemptCount, setAttemptCount] = useState(0);

  useEffect(() => {
    const uploadPhotos = async () => {
      const token = localStorage.getItem('access_token');
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/images/upload`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ images: photos }),
          credentials: 'include'
        });

        // Even if we get a CORS error, we'll continue polling
        console.log('Upload attempted');
      } catch (error) {
        console.log('Upload error handled:', error);
      }
    };

    const pollTrainingStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/images/training/status/latest`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          },
          credentials: 'include'
        });

        if (response.ok) {
          const data = await response.json();
          if (data.state === 'SUCCESS') {
            onTrainingComplete();
            return;
          }
          setStatus(`Training: ${data.state}`);
        }
        
        // Continue polling
        setTimeout(() => {
          setAttemptCount(prev => prev + 1);
        }, 5000);
      } catch (error) {
        console.log('Polling error handled:', error);
        setTimeout(() => {
          setAttemptCount(prev => prev + 1);
        }, 5000);
      }
    };

    // Initial upload on first render
    if (attemptCount === 0) {
      uploadPhotos();
    }

    // Start polling after first attempt
    pollTrainingStatus();
  }, [attemptCount, photos, onTrainingComplete]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="waiting-page"
    >
      <h2>Processing Your Photos</h2>
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
        style={{ width: 50, height: 50, border: '5px solid #f3f3f3', borderTop: '5px solid #3498db', borderRadius: '50%' }}
      />
      <p>{status}</p>
    </motion.div>
  );
};

export default WaitingPage;